<template>
  <app-form-autocomplete
    v-model="modelCompany"
    v-model:query="query"
    clearable
    item-title="properties.name"
    item-value="id"
    :items="items"
    label="Hubspot company"
    :loading="loading"
    :no-data-text="noDataText"
    :required="required"
  >
    <template #item="{ item, props }">
      <v-list-item :title="item.properties.name" v-bind="props">
        <template #append>
          <span class="text-subtle text-sm">{{ item.id }}</span>
        </template>
      </v-list-item>
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
import type { HubspotCompany } from "@pollen/core/model/hubspot.model";

const model = defineModel<string | undefined | null>({
  default: undefined,
});

const modelCompany = defineModel<HubspotCompany | undefined>("company", {
  default: undefined,
});

defineProps<{
  required?: boolean;
}>();

const query = ref("");
const items = ref<HubspotCompany[]>([]);
const loading = ref(false);
const noDataText = computed(() =>
  query.value ? undefined : "Start typing to search for Hubspot companies",
);

watchDebounced(
  query,
  async (value) => {
    if (!value) {
      items.value = [];
      return;
    }

    try {
      loading.value = true;
      items.value = await $fetch("/api/hubspot/companies", {
        query: { query: value },
      });
    } finally {
      loading.value = false;
    }
  },
  { debounce: 150 },
);

watch(modelCompany, (company) => {
  if (!company) return;

  model.value = company.id;
});

onMounted(async () => {
  if (!model.value) return;

  try {
    loading.value = true;
    modelCompany.value = await $fetch(`/api/hubspot/companies/${model.value}`);
  } finally {
    loading.value = false;
  }
});
</script>
